@import '/app/src/shared/theme/mixins.scss'; @import '/app/src/shared/theme/var.scss'; @import '/app/src/theme/var.scss';
.container {
    .mainGroup {
        display: flex;

        .icon {
            margin-top: 5px;
            margin-right: 15px;
        }

        .body {
            flex: 1;
            flex-direction: column;

            .title {
                margin-bottom: 15px;
                font-size: 21px;
                font-family: $secondary-font-family;
                line-height: 1.2;
            }

            .content {
                min-height: 34px;
                margin-bottom: 15px;
            }
        }
    }

    .checkbox {
        input {
            margin-right: 5px;
        }
    }

    .buttonGroup {
        display: flex;
    }
}
