@import '/app/src/shared/theme/mixins.scss'; @import '/app/src/shared/theme/var.scss'; @import '/app/src/theme/var.scss';
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css');
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,500,600,700,800,900');
@import url('https://fonts.googleapis.com/css?family=Barlow:300,400,500,600,700,800,900');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700;800;900&display=swap');
@import '~react-placeholder/lib/reactPlaceholder';
@import '/static/css/altive-font.css';
@import '~react-image-crop/lib/ReactCrop.scss';
@import '/static/css/slick.css';
@import '/static/css/slick-theme.css';

*,
::after,
::before {
    box-sizing: border-box;
}

textarea,
input[type='range'],
input,
input:matches([type='password'], [type='search']) {
    padding: 1px 2px; // HOTFIX: override safari user agent stylesheet
}

body {
    margin: 0;
    font-weight: 300;
    font-size: 1rem;
    font-family: $primary-font-family;
    line-height: 1.5;

    &.fixed {
        height: 100%;
        overflow: hidden;
    }
}

html {
    scroll-behavior: smooth;
}

button {
    font-weight: 300;
    font-family: $primary-font-family;
    border-style: solid;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    &:disabled {
        background-color: $button-disabled-background-color;
        cursor: default !important;
    }

    &[type='button']:not(:disabled),
    &[type='reset']:not(:disabled),
    &[type='submit']:not(:disabled),
    &:not(:disabled) {
        cursor: pointer;
    }
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

/* a tag from reboot.css */
a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
    outline: 0;
}

label {
    display: inline-block;
}

p {
    margin: 0;
    margin-bottom: 1rem;
}

.link-button {
    display: block;
    padding: 0;
    color: inherit;
    text-align: left;
    background-color: inherit;
    border: 0;
    outline: none;

    &:focus {
        outline: none;
    }
}

.altive-list {
    padding-left: 22px;
    text-align: left;
    list-style: none;

    &.dark {
        li {
            &::before {
                content: url('/static/images/svg/BulletPoint(bd)@3x.svg');
            }
        }
    }

    li {
        padding-bottom: 15px;
        line-height: 1.2;

        &::before {
            display: inline-block;
            width: 1.3em;
            margin-left: -1.3em;
            content: url('/static/images/svg/BulletPoint(pd2)@3x.svg');
        }

        &:last-child {
            padding-bottom: 0;
        }
    }
}

.hide {
    display: none;
}

.modal-backdrop {
    z-index: 100020;
}

.ReactCrop .ReactCrop__crop-selection {
    border-radius: 50%;
}
